import HelloWorld from "@/components/HelloWorld.vue";
import Main from "@/components/main.vue"
import {createRouter, createWebHistory} from "vue-router";
import login from "@/views/login.vue";
import {ElMessage} from "element-plus";
import {onMounted, ref} from "vue";
import {post} from "@/api/user";


const routes = [
    {
        path: '/', name: 'base', meta: {
            isLogin: false,
        }, component: () => import("../views/HomeView.vue"),
        children: [
            {
                path: '/order_details',
                name: 'details',
                meta: {
                    isLogin: true,
                },
                component: () => import("@/components/Order_detail.vue")
            },
            {
                path: '/notDone_order',
                name: 'not_done',
                meta: {
                    isLogin: true,
                },
                component: () => import("@/components/NotDone_order.vue")
            },
            {
                path: '/prediction',
                name: 'prediction',
                meta: {
                    isLogin: true,
                },
                component: () => import("@/components/Prediction.vue")
            },
            {
                path: '/pre_option',
                name: 'pre_option',
                meta: {
                    isLogin: true,
                },
                component: () => import("@/components/Prediction_option.vue")
            }
        ]
    },
    {
        path: '/qrLogin', name: 'login', meta: {
            isLogin: false,
        }, component: login
    }
]
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHistory(),
    routes, // `routes: routes` 的缩写
})

/**
 * 全局前置路由守卫，每一次路由跳转前都进入这个 beforeEach 函数
 */
router.beforeEach((to, from, next) => {
    // 判断是否登录
    if (to.meta["isLogin"]) {
        const token = localStorage.getItem('x-token');
        // 1、检查token是否存在，不存在直接跳转首页
        if (token) {
            // post('check_token', ).then(res => {
            //     // console.log(res)
            //     if (res){
            //         if(res.status.toString() === "200") {
            //             next();
            //         }
            //         else {
            //             next('/qrlogin');
            //         }
            //     }
            // })

            // 交给后端去校验token是否正确，前端只判断token是否存在
            next();
        } else {
            alert("验证失效，请重新登录")
            next('/qrlogin');
        }
    } else {
        next();
    }
});


export default router
