import instance, {instance2} from "../utils/post"
import axios from "axios";

export const userLogin = (data) => {
    return instance({
        url: "login",
        method: "post",
        data: data
    })
}

export const get = (url,params) =>{
    return instance({
        url: url,
        method: "get",
        params:params
    })
}

export const post = (url,data) =>{
    return instance({
        url: url,
        method: "post",
        data: data
    })
}

// 获取excel文件
export const post_excel = (url,data) =>{
    return instance2({
        url: url,
        method: "post",
        data: data,
        responseType: "blob"
    })
}



